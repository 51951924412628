import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import ScrollToTopButton from '../components/utility/buttons/ScrollToTopButton';
import UserHeader from '../components/utility/navigation/UserHeader';

const CampaignsPage = ({ title, children }: { title: string; children?: ReactNode }) => {
  const { t } = useTranslation();
  return (
    <div data-testid="campaigns-page" className="page-content">
      <UserHeader title={t(title)} />
      <ScrollToTopButton />
      <div className="mt10 pos-rel">{children}</div>
    </div>
  );
};

export default CampaignsPage;
